import React, { FunctionComponent, ReactNode } from "react";
import { PageProps } from "gatsby";
import classnames from "classnames";

import classes from "./tarieven.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import PageTitle from "../components/page-title";
import Book from "../images/book-open.svg";
import Clipboard from "../images/clipboard.svg";
import Award from "../images/award.svg";
import Archive from "../images/archive.svg";
import Container from "../components/container";

const Service: FunctionComponent<{}> = ({ children }) => (
    <div className={classes.service}>{children}</div>
);

const ServiceTitle: FunctionComponent<{ title: string, icon?: ReactNode }> = ({ title, icon = null }) => (
    <h3 className={classes.serviceTitle}>
        {icon && <span>{icon} </span>}{title}
    </h3>
);

const ServiceItem: FunctionComponent<{ title: string, price?: string, aligned?: boolean }> = ({ title, price = null, aligned = false, children }) => (
    <div className={classnames(classes.serviceItem, { [classes.alignedServiceItem]: aligned })}>
        <h4 className={classes.serviceItemTitle}>{title}</h4>

        <div className={classes.serviceItemDescription}>{children}</div>
        <p className={classes.serviceItemPrice}>
            {price ? (<span>€ {price}</span>) : <span>Kosteloos</span>}
        </p>
    </div>
);

const ServiceItemComponent: FunctionComponent<{}> = ({ children }) => (
    <div className={classes.serviceItemComponent}>{children}</div>
);

const Divider: FunctionComponent<{}> = () => <hr className={classes.divider} />;

const RatesPage: FunctionComponent<PageProps> = ({ location }) => {
    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Tarieven" description="De tarieven die bij Praktijk de Leersprong aangehouden worden." />
            <Hero />

            <PageTitle>Tarieven</PageTitle>

            <Container>
                <Service>
                    <ServiceTitle title="Bijles" icon={<Book />} />

                    <div className={classes.serviceItems}>
                        <ServiceItem title="Intakegesprek" />
                        <ServiceItem title="Losse les" price="49,50">
                            <ServiceItemComponent>1 x 45 leerlinggebonden minuten</ServiceItemComponent>
                            <ServiceItemComponent>15 minuten voorbereiding en verslaglegging</ServiceItemComponent>
                        </ServiceItem>
                    </div>
                </Service>
                <Divider />
                <Service>
                    <ServiceTitle title="Remedial teaching" icon={<Archive />} />

                    <div className={classes.serviceItems}>
                        <ServiceItem title="Intakegesprek" />
                        <ServiceItem title="Losse les" price="49,50">
                            <ServiceItemComponent>1 x 45 leerlinggebonden minuten</ServiceItemComponent>
                            <ServiceItemComponent>15 minuten voorbereiding en verslaglegging</ServiceItemComponent>
                        </ServiceItem>
                    </div>
                </Service>
                <Divider />
                <Service>
                    <ServiceTitle title="Huiswerkbegeleiding" icon={<Clipboard />} />

                    <div className={classes.serviceItems}>
                        <ServiceItem title="Intakegesprek" />
                        <ServiceItem title="Losse les" price="49,50">
                            <ServiceItemComponent>1 x 45 leerlinggebonden minuten</ServiceItemComponent>
                            <ServiceItemComponent>15 minuten voorbereiding en verslaglegging</ServiceItemComponent>
                        </ServiceItem>
                    </div>
                </Service>
                <Divider />
                <Service>
                    <ServiceTitle title="Cito-training" icon={<Award />} />

                    <div className={classes.serviceItems}>
                        <ServiceItem title="Intakegesprek" />
                        <ServiceItem title="Losse les" price="49,50">
                            <ServiceItemComponent>1 x 45 leerlinggebonden minuten</ServiceItemComponent>
                            <ServiceItemComponent>15 minuten voorbereiding en verslaglegging</ServiceItemComponent>
                        </ServiceItem>
                    </div>
                </Service>
                <Divider />
                <Service>
                    <ServiceTitle title="Overig" />

                    <div className={classes.serviceItems}>
                        <ServiceItem title="Contact met school en/of externen" price="12,50 per 15 minuten" aligned />
                        <ServiceItem title="Opstellen handelingsplan" price="50,00" aligned />
                        <ServiceItem title="Evaluatiegesprek" price="20,00 per 30 minuten" aligned />
                        <ServiceItem title="Diagnostisch onderzoek" price="150,00" aligned />
                    </div>
                </Service>
            </Container>
        </Layout>
    );
};

export default RatesPage;
